import Home from "../views/Home.vue";
import Login from "../views/Login";
import User  from "../views/User";
import Nfe from "../views/Nfe";
import Nfce from "../views/Nfce"
import Enterprise from "../views/Enterprise";
import ReportNfe from "../views/ReportNfe";
import UploadNfe from "../views/UploadNfe";
import Dashboard from "../views/Dashboard";
import Parameter from "../views/Parameter";
import CompanyGroup from "../views/CompanyGroup";
import Permission from "../views/Permission";
import Tenant from "../views/Tenant";
import License from "../views/License";
import Plans from "../views/Plans";

export default () => [
  {
    path: "/",
    name: "Login",
    component: Login,
  },

  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/User",
    name: "User",
    component: User,
  },
  {
    path: "/Nfce",
    name: "Nfce",
    component: Nfce,
  },
  {
    path: "/Paramter",
    name: "Paramter",
    component: Parameter,
  },
  {
    path: "/Enterprise",
    name: "Enterprise",
    component: Enterprise,
  },
  {
    path: "/ReportNfe",
    name: "ReportNfe",
    component: ReportNfe,
  },
  {
    path: "/UploadNfe",
    name: "UploadNfe",
    component: UploadNfe,
  },

  {
    path: "/Dashboard",
    name: "Dashboard ",
    component: Dashboard,
  },

  {
    path: "/Nfe",
    name: "Nfe",
    component: Nfe,
  },
  {
    path: "/CompanyGroup",
    name: "CompanyGroup",
    component: CompanyGroup ,
  },

  {
    path: "/Permission",
    name: "Permission",
    component: Permission ,
  },

  {
    path: "/Tenant",
    name: "/Tenant",
    component: Tenant,
  },
  
  {
    path: "/License",
    name: "License",
    component: License,
  },

  {
    path: "/Plans",
    name: "Plans",
    component: Plans,
  }

];
