import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import alert_api from "./alert_api";
import user from './user';
import enterprise from './enterprise';
import companygroup from './companygroup';
import parameter from './parameter';
import tenant from './tenant';
import license from './license';
import plans from './plans';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alert_api,
    user ,
    enterprise,
    companygroup,
    parameter,
    tenant,
    license,
    plans,
  },
});
