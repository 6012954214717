<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="10">
        <v-text-field
          outlined
          label="*Rua"
          v-model="component.enterprise.street"
          :rules="campoRules"
        >
        </v-text-field>
      </v-col>
      <v-col
        ><v-text-field
          outlined
          label="*Número"
          v-model="component.enterprise.number"
          :rules="campoRules"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          label="*Bairro"
          v-model="component.enterprise.district"
          :rules="campoRules"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          label="Complemento"
          v-model="component.enterprise.complement"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Telefone"
          outlined
          v-model="component.enterprise.telephone"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          outlined
          v-model="component.oldState"
          return-object
          :items="component.initials"
          label="*Estado"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          outlined
          :items="component.cities"
          v-model="component.newcity"
          item-text="nome"
          return-object
          label="*Cidade"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-text-field
          outlined
          label="*CEP"
          v-model="component.enterprise.cep"
          v-mask="'#####-###'"
          :rules="campoRules"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-select
          label="*Pais"
            v-model="component.enterprise.description_country"
          :items="initialsPais"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// import { campoRules } from "../script";
import axios from "axios";

export default {
  props: {
    component: Object(),
  },

  data: () => ({
    // campoRules,

    initialsPais: [
      { text: "BRASIL", value: "1058" },
      { text: "BOLIVIA", value: "973" },
      { text: "ARGENTINA", value: "639" },
    ],

    initials: [
      { text: "Acre", value: "ac" },
      { text: "Alagoas", value: "al" },
      { text: "Amapá", value: "ap" },
      { text: "Amazonas", value: "am" },
      { text: "Bahia", value: "ba" },
      { text: "Ceará", value: "ce" },
      { text: "Espírito Santo", value: "es" },
      { text: "Goiás", value: "go" },
      { text: "Maranhão", value: "ma" },
      { text: "Mato Grosso", value: "mt" },
      { text: "Mato Grosso do Sul", value: "ms" },
      { text: "Minas Gerais", value: "mg" },
      { text: "Pará", value: "pa" },
      { text: "Paraíba", value: "pb" },
      { text: "Paraná", value: "pr" },
      { text: "Pernambuco", value: "pe" },
      { text: "Piauí", value: "pi" },
      { text: "Rio de Janeiro", value: "rj" },
      { text: "Rio Grande do Norte", value: "rn" },
      { text: "Rio Grande do Sul", value: "rs" },
      { text: "Rondônia", value: "ro" },
      { text: "Roraima", value: "rr" },
      { text: "Santa Catarina", value: "sc" },
      { text: "São Paulo", value: "sp" },
      { text: "Sergipe", value: "se" },
      { text: "Tocatins", value: "to" },
      { text: "Distrito Federal", value: "df" },
    ],
    cities: Array(),
    oldState: Object(),
  }),
  watch: {
    oldState(val) {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
        )
        .then((response) => {
          this.cities = response.data;
        });
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
  },

  mounted() {
    this.oldState = this.initials.find(
      (e) => e.text == this.component.enterprise.state
    );
  },
};
</script>
