<template>
<Container>
   <Dashboard/>
  </Container>
</template>

<script>
import Dashboard from "../components/Dashboard/index.vue"
import Container from "../components/Dashboard/Container"

export default {
    components:{
        Dashboard,
        Container,
    }
    
}
</script>

<style scoped>

</style>