<template>
  <v-form  ref="form" v-model="component.valid"  @submit.prevent="submit">
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Fornecedor Inicial" 
          v-model="component.report.providerinitialcode"
          type="number"
          :rules="[
                  () => !!component.report.providerinitialcode || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field 
          outlined 
          label="Cod. Fornecedor Final" 
          v-model="component.report.providerfinalcode"
          type="number"
          :rules="[
                  () => !!component.report.providerfinalcode || 'Campo Obrigatorio'
                ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field outlined label="Número de Nota Fiscal" v-model="component.report.number_nf" ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data de Entrada inicial"
          type="date"
          v-model="component.report.initialentrydate"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data de Entrada final"
          type="date"
          v-model="component.report.finalentrydate"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mr-1 ml-1">
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data de Emissão inicial"
          type="date"
          v-model="component.report.initialdateissue"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4" xl="4" lg="4">
        <v-text-field
          label="Data de Emissão final"
          type="date"
          v-model="component.report.finaldateissue"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// import { GET_ALL_PROVIDER } from "../../../../store/actions/provider";
// import { dispatchGetAll } from "../../../Methods/dispatchGetAll";
// import { mapGetters } from "vuex";

export default {
  props:{
    component: Object
  },

  data() {
    return {
     // providers: null
    }
  },

  methods:{
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    // async GetAll(page = 1) {
    //   await dispatchGetAll(this, GET_ALL_PROVIDER, {
    //     type_people: "PhysicalPeople",
    //     page,
    //   });
    //   await dispatchGetAll(this, GET_ALL_PROVIDER, {
    //     type_people: "LegalPeople",
    //     page,
    //   });
    // },
  },

  //  computed: {
  //   ...mapGetters([
  //     "getPhisycalProvider",
  //     "getLegalProvider",
  //   ]),
  //   },

  // created() {
  //   this.GetAll();
  //   // this.providers = this.getPhisycalProvider.concat(this.getLegalProvider)
  //   // this.providers.sort(function (a, b) {
  //   //   //console.log(a.id, b.id, 100);
  //   //   return a.id - b.id
  //   // })
  //   // console.log(100, this.providers);
  // },
};
</script>

<style>
</style>