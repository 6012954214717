<template>
  <Container>
    <v-card>
      <v-card-text
        ><CardTitle :downTitle="changeDialog" :component="this"
      /></v-card-text>
      <v-layout>
        <Info :component="this" />
      </v-layout>
      <AddDialog
        :dialog="dialog"
        :changeDialog="changeDialog"
        :submit="submit"
      />
      <v-card-text>
        <v-text-field
          outlined
          label="Pesquisar"
          append-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </v-card-text>
      <Table :component="this" :header="header" :items="table_downtitle" />
    </v-card>
    <AlertAPI />
  </Container>
</template>

<script>
import AlertAPI from "../components/alert_api/alert_api";
import Container from "../components/UploadNfe/Container";
import CardTitle from "../components/UploadNfe/CardTitle";
import Info from "../components/UploadNfe/Info";
import Table from "../components/UploadNfe/Table";
import AddDialog from "../components/UploadNfe/AddDialog";
// import {
//   GET_ALL_BILLSPAY,
//   DOWNLOAD_BILLSPAY,
//   FIND_BILLSPAY,
// } from "../store/actions/bills_pay";
// import { GET_ALL_CASHIER } from "../store/actions/cashier";
// import { mapGetters } from "vuex";
export default {
  components: {
    Container,
    CardTitle,
    Info,
    Table,
    AddDialog,
    AlertAPI,
  },
  data: () => ({
    valid: Boolean(),
    cashier: [],
    date: {
      date_initial: String(),
      date_final: String(),
    },
    current: Number(),
    total_table: Number(),
    total: Number(),
    dialog: Boolean(),
    enterpriseId: Number(),
    table_downtitle: Array(),
    downtitle: {
      id: Number(),
      cashier: String(),
      issuance_due: String(),
      title_balance: Number(),
      date_low: String(),
      enterprise_id: Number(),
      down: Boolean(),
    },
    search: String(),

  }),
  // computed: {
  //   ...mapGetters(["getBillPays", "getCashier"]),
  // },
  // methods: {
  //   async GetBills_Pay(page = 1) {
  //     let value = {
  //       page: page,
  //       limit: 10,
  //       paginate: true,
  //     };
  //     await this.$store.dispatch(GET_ALL_BILLSPAY, value);
  //   },

  //   async GetAllNoPage() {
  //     let value = {
  //       page: null,
  //       limit: null,
  //       paginate: false,
  //     };
  //     await this.$store.dispatch(GET_ALL_CASHIER, value);
  //   },

  //   table_struct(val) {
  //     let index = -1;
  //     let tableItem = Object();
  //     tableItem;
  //     let auxItem = Object();

  //     while (this.table_downtitle.length) {
  //       this.table_downtitle.pop();
  //     }
  //     if (val) {
  //       val.forEach((e) => {
  //         auxItem.id = e.id;
  //         auxItem.status = e.status;
  //         auxItem.description_moviment = "Título de N°" + e.number_title;
  //         auxItem.enterprise_id = e.enterprise_id;
  //         auxItem.account_plans_id = e.account_plans_id;
  //         auxItem.issuance_due = e.issuance_due;
  //         if (e.provider.type_people === "PhysicalPeople") {
  //           auxItem.provider = e.provider.people.physicalpeople.name;
  //         } else {
  //           auxItem.provider = e.provider.people.legalpeople.fantasyName;
  //         }
  //         auxItem.type_title = e.type_title;
  //         auxItem.issuance_date = e.issuance_date;
  //         auxItem.issuance_due = e.issuance_due;
  //         auxItem.number_title = e.number_title;
  //         auxItem.value_title = e.value_title;
  //         auxItem.title_balance = parseFloat(e.title_balance);
  //         tableItem = { ...auxItem };

  //         index = this.table_downtitle.findIndex((value) => value.id === e.id);
  //         if (index >= 0) {
  //           this.table_downtitle.splice(index, 1, tableItem);
  //         } else {
  //           this.table_downtitle.push(tableItem);
  //         }
  //       });
  //     }
  //   },

  //   async submit() {
  //     let titles = Array();
  //     this.table_downtitle.forEach((e) => e.down && titles.push(e));
  //     try {
  //       titles.forEach((e) => {
  //         this.downtitle.id = e.id;
  //         this.downtitle.account_plans_id = e.account_plans_id;
  //         this.downtitle.title_balance = e.title_balance;
  //         this.downtitle.enterprise_id = e.enterprise_id;
  //         this.downtitle.issuance_due = e.issuance_due;
  //         let downtitle = { ...this.downtitle };

  //         this.$store.dispatch(DOWNLOAD_BILLSPAY, downtitle);
  //       });
  //     } catch (error) {
  //       alert(error);
  //     } finally {
  //       this.changeDialog();
  //     }
  //   },
  //   changeDialog() {
  //     this.dialog = !this.dialog;
  //   },
  //   find() {
  //     this.date.enterprise_id = this.enterpriseId;
  //     this.date.date_initial = this.convertDate(this.date.date_initial);
  //     this.date.date_final = this.convertDate(this.date.date_final);

  //     this.$store.dispatch(FIND_BILLSPAY, this.date);
  //   },

  //   //Formato aceitável no método de pesquisa
  //   convertDate(item) {
  //     let year = item[0] + item[1] + item[2] + item[3];
  //     let mouth = item[5] + item[6];
  //     let day = item[8] + item[9];
  //     return year + mouth + day;

  //     // return day + mouth + year;
  //   },
  //   current_Date() {
  //     var dNow = new Date();
  //     var month = dNow.getMonth() + 1;
  //     var dec, dFull;

  //     if (month < 10) {
  //       month = "0" + month;
  //     }

  //     if (dNow.getDate() <= 9) {
  //       dec = "0";

  //       dFull = dNow.getFullYear() + "-" + month + "-" + dec + dNow.getDate();
  //       this.date.date_initial = dFull;
  //       this.date.date_final = dFull;
  //     } else {
  //       dFull = dNow.getFullYear() + "-" + month + "-" + dNow.getDate();
  //       this.date.date_initial = dFull;
  //       this.date.date_final = dFull;
  //     }
  //   },
  // },
  // watch: {
  //   getBillPays: {
  //     handler: function (val) {
  //       this.total_table = val.last_page;
  //       this.current = val.current_page;
  //       this.table_struct(val.data);
  //     },
  //     deep: true,
  //   },
  //   table_downtitle: {
  //     handler: function (val) {
  //       this.total = 0;
  //       val.forEach((e) => {
  //         if (e.down === true) {
  //           this.total += e.title_balance;
  //         }
  //       });
  //     },
  //     deep: true,
  //   },

  //   current: function (val) {
  //     this.GetBills_Pay && this.GetBills_Pay(val);
  //   },

  //   getCashier: function (val) {
  //     this.cashier = Object.keys(val).map((key) => val[key]);
  //   },
  // },
  // created() {
  //   this.GetAllNoPage();
  //   this.GetBills_Pay();
  //   this.current_Date();
  // },
};
</script>

<style>
</style>