<template>
  <v-col>
    <!-- <Transition name="fade"> -->
    <v-expand-transition>
      <div v-show="component.expandTitle" class="titleLogo">
        <div>
          <v-img width="100" height="100" src="../logo.png" />
        </div>
        <div class="divTitle">
          <p class="titleLogin">TopWay Systems</p>
        </div>
      </div>
    </v-expand-transition>
    <!-- </Transition> -->

    <v-expand-transition>
      <v-card
        class="pa-10"
        v-show="component.expand"
        rounded="xl"
        elevation="15"
        id="cardModification"
      >
        <v-col>
          <v-row>
            <div class="titleCard">
              <h1 class="h1login">Painel Administrativo - Top Way</h1>
              <p class="pharagraphlogin">
                Insira suas credencias para efetuar o login
              </p>
            </div>
          </v-row>
          <v-row>
            <v-text-field
              background-color="#faf9f9"
              prepend-inner-icon="mdi-email"
              class="rounded-lg"
              solo
              label="Email"
              data-cy="test_email"
              v-model="component.email"
            >
            </v-text-field>
          </v-row>
          <v-row>
            <v-text-field
              prepend-inner-icon="mdi-key"
              class="rounded-lg"
              solo
              
              @click:append="component.setShowPassword"
              :append-icon="component.showPassWord ? 'mdi-eye' : 'mdi-eye-off'"
              :type="component.showPassWord ? 'text' : 'password'"
              background-color="#faf9f9"
              label="Senha"
              v-model="component.password"
            ></v-text-field>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-btn
              @click="component.submit"
              color="#edf2fb"
              class="rounded-lg blue--text darken-4--text"
              large
              width="100%"
              :loading="componentisLoading"
              :disabled="component.isLoading"
            >
              <v-progress-circular
                v-if="component.isLoading == true"
                indeterminate
                color="white"
              ></v-progress-circular>
              <v-text-card v-if="component.isLoading == false">
                Login
              </v-text-card>
            </v-btn>
          </v-row>
        </v-col>
      </v-card>
    </v-expand-transition>
  </v-col>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
};
</script>

<style>
@import "../css/computer-responsive.css";
</style>



