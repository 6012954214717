/* eslint-disable no-unused-vars */


const {
  ADD_TENANT,
  
} = require("./actions/tenant");



const moduleTenant = {
state: () => ({
  tenantData: {},
  }),

mutations: {
  ADD_TENANT(state, payload) {
    state.tenantData = payload
  },

},
actions: {
  ADD_TENANT(context, payload) {
    context.commit('ADD_TENANT', payload)
  }
},

getters: {
  getTenant: (state) => ({
    ...state.tenantData,
  }),
},
};


export default moduleTenant;