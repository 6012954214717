<template>
  <v-app>
    <Struct v-show="isValid"/>
    <v-main>
      <router-view></router-view>

      
        <v-footer v-if="isValid" absolute class="success white--text">
          <v-col cols="12" class="text-center">
            Copyright &copy; {{ new Date().getFullYear() }} -
            <strong>Top Way</strong>
          </v-col>
        </v-footer>
      
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Struct from "./components/Struct/index.vue";

export default {
  components: {
    Struct,
  },

  data: () => ({
    isValid: Boolean(),
  }),
  updated() {
    this.isValid = this.$store.getters["auth/getisAuth"];
  },
  mounted() {
    this.isValid = this.$store.getters["auth/getisAuth"];
  },
  computed: {
    ...mapGetters(["auth/getisAuth"]),
  },
};
</script>
