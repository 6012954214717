<template>
  <div class="frame">
    <!-- Computer -->
    <div class="computerFrame d-none d-md-flex d-lg-flex d-xl-flex">
      <Card :component="this" />
    </div>
    <!-- tablet -->
    <div class="tabletFrame d-none d-sm-flex d-md-none">
      <Card :component="this" />
    </div>
    <!-- Mobile -->
    <div class="d-flex d-sm-none">
      <Card :component="this" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Card from "./component/card.vue";
export default {
  props: {
    source: String,
  },
  name: "Login",
  components: {
    Card,
  },

  data: () => ({
    expandTitle: Boolean(),
    expand: Boolean(),
    showPassWord: Boolean(),
    email: "",
    password: "",
    isLoading: Boolean(),
  }),
  methods: {
    setShowPassword() {
      this.showPassWord = !this.showPassWord;
    },
    ...mapActions({
      login: "auth/login",
      
    }),
    submit() {
      this.isLoading = true;
      const login = {
        email: this.email,
        password: this.password,
      };
      this.login(login)
        .then(() => {
          this.$router.replace({
            name: "Home",
            path: "/Home",
          });
        })
        .finally(() => (this.isLoading = false));

      this.loginApiFiscal({ email: "admin@admin.com", password: "way563781" })
        .then(() => {
          console.log("autenticado");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  beforeMount() {
    this.expand = false;
    this.expandTitle = false;
  },
  mounted() {
    setTimeout(() => {
      this.expandTitle = true;
    }, 500);
    setTimeout(() => {
      this.expand = true;
    }, 1000);
  },
};
</script>
<style >
.frame {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>


