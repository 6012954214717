<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <CardTitle :component="this"/>
      </v-card-text>
      <v-card-text>
        <v-text-field
          outlined
          v-model="search"
          label="Pesquisar"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :change_addDialog="change_addDialog"
        :destroy_object="destroy_object"
        :search="search"
      />
      <AddDialog v-if="addDialog" :component="this" />
    </v-card>
    <AlertAPI />
    <loadScreen />
  </Container>
</template>

<script>
import Container from "../components/Enterprise/Container";
import CardTitle from "../components/Enterprise/CardTitle";
import Table from "../components/Enterprise/Table";
import AlertAPI from "../components/alert_api/alert_api";
import AddDialog from "../components/Enterprise/AddDialog";
import {
  GET_ALL_ENTERPRISE,
} from "../store/actions/enterprise";


export default {
  components: {
    Container,
    CardTitle,
    Table,
    AddDialog,
    AlertAPI,
  },

  data: () => ({
    search: "",
    addDialog: false,
    destroyDialog: false,
    idToDestroy: 0,
    objectToUpdate: {},
    permission: Object(),
  }),

  methods: {
    async GetAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
    },
    async change_addDialog(item) {
      this.addDialog = !this.addDialog;
      this.objectToUpdate = { ...item };
    },
  },
  created() {
    this.GetAll();
  },

};
</script>

<style scoped></style>
