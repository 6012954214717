<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title primary-title>Novo Plano</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            class="mt-6"
          >
          <v-row>
            <v-col
              cols="8">
              <v-combobox
                label="*Status"
                v-model="status"
                :items="items"
                :rules="[() => !!status || 'Campo Obrigatorio']"
                outlined
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12">
               <v-text-field
                label="*Nome do Plano"
                v-model="name"
                :counter="200"
                :rules="[() => !!name || 'Campo Obrigatorio', name => name.length <= 200 || 'O nome não pode ultrapassar 200 caracteres',]"

                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12">
               <v-text-field
                label="Url"
                v-model="url"
                :counter="200"
                :rules="[() => url => url.length <= 200 || 'A Url não pode ultrapassar 200 caracteres']"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="8">
               <v-text-field
                label="Preço do Plano"
                v-model="price"
                :rules="[() => !!price || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="10"
            >
              <v-textarea
                filled
                auto-grow
                label="Detalhes do Plano"
                rows="4"
                row-height="30"
                outlined
                v-model="details"
              ></v-textarea>
            </v-col>
            <v-col
              align-self="center"
              cols="2">
              <v-btn
                class="mx-2"
                fab
                dark
                color="indigo"
                @click="addDetail"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>

          </v-row>
              
            
            
            <v-divider></v-divider>
            <Table
              :plansItem="plansItem"/>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="closeDialog" 
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="submit"
                  
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Table from "../AddDialog/DialogTable"
export default {
    components: {
      Table,
    },
    props: {
      dialog: Boolean,
    },
    data() {
        return {
            valid: true,
            status: '',
            name: '',
            url: '',
            price: null,
            items: ['Ativo', 'Inativo', 'Não Visível'],
            plansItem: [],
            details: '',    
        }
    },

    methods: {
        
        closeDialog() {
            this.dialog = false
             this.$emit('changeDialog', this.dialog)
             this.$refs.form.reset()
             this.plansItem = []
            
        },

        
        submit() {
            const plan = {
                status: this.status,
                name: this.name,
                url: this.url,
                price: this.price,
                description: this.plansItem,
            }
            this.$store.dispatch('ADD_PLAN', plan)
            this.$refs.form.reset()
            this.dialog = false
            this.$emit('changeDialog', this.dialog)
            this.plansItem = []
        },


        addDetail() {
          const detail = {
            item: this.plansItem.length + 1,
            details: this.details,
          }
          this.plansItem.push(detail)
          this.details = ''
          console.log(this.plansItem);
        }
    },
}
</script>

<style>

</style>