<template>
  <div>
    <v-list link>
      <v-list-item class="px-2">
        <v-list-item-avatar align-center>
          <v-img
            src="https://thumbs.dreamstime.com/b/businessman-icon-vector-male-avatar-profile-image-profile-businessman-icon-vector-male-avatar-profile-image-182095609.jpg"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="title white--text">{{
            user.name
          }}</v-list-item-title>
          <v-list-item-subtitle class="white--text">{{
            user.email
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
        <v-divider></v-divider>
    <v-list-item to="/Home" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-home-plus</v-icon
          >Home</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-divider></v-divider>
     <v-list-item to="/CompanyGroup" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-home-city-outline</v-icon
          >Grupo de Empresa</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-divider></v-divider>
     <v-list-item to="/Enterprise" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-domain</v-icon
          >Empresa</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-divider></v-divider>
     <v-list-item to="/License" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-license</v-icon
          >Licença</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-divider></v-divider>
     <v-list-item to="/Plans" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-note-edit-outline</v-icon
          >Planos</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-divider></v-divider>
     <v-list-item to="/Tenant" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-format-list-group-plus</v-icon
          >Tenants</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-list-item to="/permission" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-cash-minus</v-icon
          >Permissões</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-divider></v-divider>
     <v-divider></v-divider>
      <v-list-item to="/User" color="white">
      <v-list-item-content>
        <v-list-item-title class="white--text title ma-1">
          <v-icon class="mr-4 white--text">mdi-account</v-icon
          >Usuários</v-list-item-title
        >
      </v-list-item-content>
    </v-list-item>
     <v-divider></v-divider>
  </div>
</template>

<script>


export default {

  data: () => ({
    user: Object,
  }),
  mounted() {
    let user = this.$store.getters["auth/user"].user;
    this.user = user || {};
  },
};
</script>

<style>
.navDrop {
  padding-left: 25px !important;
}
</style>
