<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <v-row>
          <v-card-title class="ml-2">Usuários</v-card-title>
          <v-spacer></v-spacer>
          <AddDialog
            :component="this"
            v-if="addDialog"
            :title="title"
            :addDialog="addDialog"
            :actionDialog="close"
          />
          <v-btn color="success" class="mr-5 mt-4" @click="activeAdd()"
            >Adicionar</v-btn
          >
        </v-row>
        <v-text-field
          outlined
          class="ma-2"
          label="Pesquisar"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :editItem="editItem"
        :changeDestroyDialog="changeDestroyDialog"
        :change_password="change_password"
      />
      <!-- <DestroyDialog
        v-if="destroyDialog"
        :component="this"
        :destroy_object="destroy_object"
      /> -->
      <!-- <ChangePass v-if="dialogPass" :component="this" /> -->
    </v-card>
    <AlertApi />
  </Container>
</template>

<script>
import Container from "../components/User/Container";
import AddDialog from "../components/User/AddDialog";
import Table from "../components/User/Table";
import AlertApi from "../components/alert_api/alert_api";
import { GET_ALL_USER } from "../store/actions/user";
export default {
  components: {
    AddDialog,
    Container,
    Table,
    // DestroyDialog,
    // ChangePass,
    AlertApi,
  },
  data: () => ({
    addDialog: Boolean(),
    currentItem: Object(),
    permition: undefined,
    user: {
      id: String(),
      status: true,
      name: String(),
      password: String(),
      email: String(),
      enterprises:[],
      // permissions: Array(),
      // roles: Array(),
    },
    title: String(),
    user_confirm_pass: String(),
    user_finish: Object(),
    allUsers: Array(),
    isLoading: Boolean(),
    isAuth: Boolean(),
    destroyDialog: Boolean(),
    changePassword: Boolean(),
    itemToDestroy: Object(),
    dialogPass: Boolean(),
  }),
  methods: {
    activeAdd() {
      this.addDialog = !this.addDialog;
      this.changePassword = true;
    },

    close() {
      this.addDialog = !this.addDialog;
    },
    async getAll() {
      let value = {
        page: 1,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_USER, value);
    },
  },

  async mounted() {
    await this.getAll();
  },
};
</script>

<style></style>
