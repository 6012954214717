<template>
  <v-row justify="center">
    <v-dialog v-model="this.component.addDialog" max-width="1300">
      <v-card>
        <v-container>
          <v-card-title>Adicionar Nova Grupo de Acesso</v-card-title>
          <v-divider></v-divider>
          <v-text-field
            class="mt-4"
            label="Nome do Grupo"
            outlined
            v-model="component.form.group_name"
          ></v-text-field>
          <v-text-field
            label="Descrição Grupo"
            outlined
            v-model="component.form.group_description"
          ></v-text-field>
          <v-data-table
            :headers="header"
            :items="component.items"
            no-data-text="Sem registro!"
            :item-class="fadein"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, -1],
              'items-per-page-text': 'Items por página',
            }"
          >
            <template v-slot:item.enable="{ item }">
              <v-simple-checkbox
                :ripple="false"
                v-model="item.enable"
                color="success"
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.level="{ item }">
              <v-select
                :items="component.levels"
                dense
                :ripple="false"
                chips
                multiple
                class="mt-4"
                outlined
                v-model="item.level"
              ></v-select>
            </template>
            <v-spacer></v-spacer>
          </v-data-table>
          <v-divider></v-divider>
          <v-row class="mt-4">
            <v-spacer></v-spacer>
            <v-btn class="error" @click="close()"
              ><v-icon class="mr-1">mdi-cancel</v-icon>Cancelar</v-btn
            >
            <v-btn
              class="ml-4 mr-4 success"
              @click="submit"
              :disabled="Isload"
              :loading="Isload"
            >
              <v-icon class="mr-1">mdi-content-save</v-icon>
              {{ this.component.form.id != undefined ? "Atualizar" : "Salvar" }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
  props: {
    component: Object,
  },

  data: () => ({
    header: [
      { text: "#", value: "id" },
      { text: "Rotinas", value: "routine" },
      { text: "Habilitar?", value: "enable" },
      { text: "Nível", value: "level" },
    ],
    Isload: Boolean(),
  }),

//   methods: {
//     close() {
//       this.component.addDialog = !this.component.addDialog;
//     },
//     fadein: function () {
//       return " fade";
//     },
//     submit() {
//       this.component.submit();
//     },
//   },
//   async mounted() {
//     let alocItem = this.component.item_Aloc;
//     this.component.items = Array();
//     await this.component.getAllPermissions();
//     this.getPermissions.forEach((item, i) => {
//       setTimeout(() => {
//         let array = alocItem ? alocItem.permissions : null;

//         let current = array
//           ? array.find((r) => r.route_name == item.route_name)
//           : null;
//         this.component.items.push({
//           id: item.id,
//           routine: item.display_name,
//           enable: current ? true : false,
//           level: current ? current.pivot.permission_col.split(",") : [],
//         });
//       }, 100 * i);
//     });

//     if (alocItem) {
//       let form = {
//         id: alocItem.id,
//         group_name: alocItem.display_name,
//         group_description: alocItem.description,
//       };
//       this.component.form = form;
//     }
//   },
//   computed: {
//     ...mapGetters(["getPermissions"]),
//   },
//   destroyed() {
//     this.component.item_Aloc = null;
//     this.component.clearForm();
//   },
//   watch: {
//     "component.isload": function (load) {
//       this.Isload = load;
//     },
//   },
};
</script>

<style>
.zeroOp {
  opacity: 0;
}
.fade {
  -webkit-animation: fadein 1s ease-in; /* Safari, Chrome and Opera > 11.1 */
  -moz-animation: fadein 1s ease-in; /* Firefox < 16 */
  -ms-animation: fadein 1s ease-in; /* Internet Explorer */
  -o-animation: fadein 1s ease-in; /* Opera < 11.1 */
  animation: fadein 1s ease-in;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>