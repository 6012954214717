<template>
  <div>
    <v-toolbar class="success">
      <div class="d-flex align-center">
        <v-icon color="white" @click="openNav()">mdi-menu</v-icon>

        <v-img
          alt="TopWay Logo"
          class="shrink ml-2"
          contain
          src="./logo.png"
          transition="scale-transition"
          width="80"
        />
        <v-toolbar-title class="white--text font-weight text-h5"
          >TOP WAY-GESTÃO</v-toolbar-title
        >
      </div>
      <v-spacer></v-spacer>
      <v-icon large color="white" @click="logout">mdi-logout</v-icon>
    </v-toolbar>

    <v-container>
      <NavBar :component="this" />
    </v-container>
  </div>
</template>

<script>
import NavBar from "../Navbar";
export default {
  components: {
    NavBar,
  },
  name: "Struct",

  data: () => ({
    drawer: false,
  }),

  methods: {
    openNav() {
      this.drawer = !this.drawer;
    },
    logout() {
      this.$store.dispatch("auth/logOut");
    },
  },
};
</script>

<style>
</style>