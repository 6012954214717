<template>
  <v-form v-model="valid">
    <v-row class="ml-2">
      <v-col >
        <v-text-field
          v-model="component.date.date_initial"
          label="Data Inicial"
          type="date"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          outlined
        ></v-text-field>
      </v-col>

      <v-col >
        <v-text-field
          v-model="component.date.date_final"
          label="Data Final"
          :rules="[(v) => !!v || 'Campo obrigatório para Buscar']"
          type="date"
          outlined
        >
        </v-text-field>
      </v-col>

      <v-col
        ><v-btn
          class="mt-2"
          :disabled="!valid"
          color="success"
          rounded
          @click="component.find()"
          ><v-icon>mdi-magnify</v-icon></v-btn
        ></v-col
      >




    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({
    valid: Boolean(),
    data: String(),
  }),
};
</script>

<style>
</style>