import axios from "axios"

const {
    GET_ALL_PARAMETER,

} = require('./actions/parameter')


const moduleParameter = {
    state: () => ({
        parameter: Object()
    }),
    mutations: {
        GET_ALL_PARAMETER: (state, payload) => {
            state.parameter = payload
        }
    },
    actions: {
        GET_ALL_PARAMETER({ commit }, payload) {
      
            axios.post("parameter/index", {
                page: payload.page,
                limit: payload.limit,
                paginate: payload.paginate,
              }).then((response) => {
                console.log(response.data)
                commit(GET_ALL_PARAMETER, response.data.parameter)
            })
        }
    },
    getters: {
        getParameter: (state) => state.parameter
    }
}

export default moduleParameter;