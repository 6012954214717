var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1300"},model:{value:(this.component.addDialog),callback:function ($$v) {_vm.$set(this.component, "addDialog", $$v)},expression:"this.component.addDialog"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_vm._v("Adicionar Nova Grupo de Acesso")]),_c('v-divider'),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Nome do Grupo","outlined":""},model:{value:(_vm.component.form.group_name),callback:function ($$v) {_vm.$set(_vm.component.form, "group_name", $$v)},expression:"component.form.group_name"}}),_c('v-text-field',{attrs:{"label":"Descrição Grupo","outlined":""},model:{value:(_vm.component.form.group_description),callback:function ($$v) {_vm.$set(_vm.component.form, "group_description", $$v)},expression:"component.form.group_description"}}),_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.component.items,"no-data-text":"Sem registro!","item-class":_vm.fadein,"footer-props":{
            'items-per-page-options': [5, 10, 15, -1],
            'items-per-page-text': 'Items por página',
          }},scopedSlots:_vm._u([{key:"item.enable",fn:function(ref){
          var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"color":"success"},model:{value:(item.enable),callback:function ($$v) {_vm.$set(item, "enable", $$v)},expression:"item.enable"}})]}},{key:"item.level",fn:function(ref){
          var item = ref.item;
return [_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.component.levels,"dense":"","ripple":false,"chips":"","multiple":"","outlined":""},model:{value:(item.level),callback:function ($$v) {_vm.$set(item, "level", $$v)},expression:"item.level"}})]}}])},[_c('v-spacer')],1),_c('v-divider'),_c('v-row',{staticClass:"mt-4"},[_c('v-spacer'),_c('v-btn',{staticClass:"error",on:{"click":function($event){return _vm.close()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cancel")]),_vm._v("Cancelar")],1),_c('v-btn',{staticClass:"ml-4 mr-4 success",attrs:{"disabled":_vm.Isload,"loading":_vm.Isload},on:{"click":_vm.submit}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(this.component.form.id != undefined ? "Atualizar" : "Salvar")+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }