<template>
  <v-card class="ma-5" elevation="5" height="94%">
    <Home />
  </v-card>
</template>

<script>
import Home from "../components/home/main";
export default {
  components: {
    Home,
  },
};
</script>

<style>
</style>