import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


export default new Vuetify({
    icons: {
      iconfont: 'mdiSvg',
    },
    theme: {
      themes: {
        light: {
          success: '#264867',
          primary: '#01579B',
          secondary: '#FFFFFF'
        }
      }
    },
  });