<template>
  <div>
    <v-data-table
      no-data-text="Sem registro!"
      :headers="headers"
      :items="items"
      hide-default-footer
      :footer-props="{
        'items-per-page-options': [5, 10, 20, -1],
        'items-per-page-text': 'Items por página',
      }"
    >
      <template v-slot:item.down="{ item }">
        <v-simple-checkbox
          color="success"
          :ripple="false"
          v-model="item.down"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="component.current"
      :length="component.total_table"
      color="success"
      :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    component: Object(),
    items: Array,
  },
    data: () => ({
    headers: [
      { text: "Status", value: "status" },
      { text: "Data de Emissão", value: "date_of_issue" },
      { text: "Cliente", value: "cliente.people.name" },
      { text: "Número Nota", value: "number_grade" },
      { text: "Série", value: "series" },
      { text: "Valor dos Produtos", value: "value_total_products" },
      { text: "Valor da Nota", value: "value_total_note" },
      { text: "Base ICMS", value: "base_calc_icms_total" },
      { text: "Valor ICMS", value: "value_total_note" },
      { text: "Base IPI", value: "value_total_note" },
      { text: "Valor IPI", value: "value_total_note" },
      { text: "Valor Isento", value: "value_total_note" },
      { text: "Valor Outros", value: "value_total_note" },
      { text: "Ações", value: "actions" },
    ],


    })

};
</script>

<style>
</style>