<template>
  <v-row>
    <v-card-title> Empresas </v-card-title>
    <v-spacer></v-spacer>
    <v-btn
      class="success mr-2 mt-6"
      @click="
        () => {
          active_addDialog();
        }
      "
    >
      Adicionar</v-btn
    >
  </v-row>
</template>

<script>
export default {
  props: {
    component: Object(),
  },

  data: () => ({}),

  methods: {
    active_addDialog() {
      this.component.addDialog = !this.component.addDialog;
    },
  },
};
</script>
