<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="dialog"
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title>Nova Licença</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            class="mt-6"
          >
          
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="firstDate"
                    label="*Data inicial de vencimento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="firstDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="lastDate"
                    label="*Data final de vencimento"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="lastDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

           </v-row>
            
          <v-row>
            <v-col
              cols="6">
              <v-text-field
                label="*Licença"
                v-model="license"
                :rules="[() => !!license || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="6">
               <v-select
                outlined
                dense
                return-object
                flat
                height="20px"
                label="Empresa"
                item-value="id"
                loader-height
                >Adicionar</v-select
              >
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="closeDialog"
                
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="submit"
                  
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
    props: {
      dialog: Boolean,
    },
    data() {
        return {
            valid: true,
            firstDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            lastDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            modal: false,
            license: '',
            // enterprise: '',
        }
    },

    methods: {
        
        closeDialog() {
            this.dialog = false
             this.$emit('changeDialog', this.dialog)
             this.$refs.form.reset()
            
        },

        
        submit() {
            const license = {
                firstDate: this.firstDate,
                lastDate: this.lastDate,
                license: this.license,
                // enterprise: this.enterprise,
            }
            this.$store.dispatch('ADD_LICENSE', license)
            this.$refs.form.reset()
            this.dialog = false
            this.$emit('changeDialog', this.dialog)
            this.firstDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.lastDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            
        }
    },
}
</script>

<style>

</style>