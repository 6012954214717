var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.result,"no-data-text":"Sem registro!","hide-default-footer":"","footer-props":{
      'items-per-page-options': [5, 10, 20, -1],
      'items-per-page-text': 'Items por página',
    },"loading":_vm.component.isload},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":function () {
            _vm.edit_item(item);
          }}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":function () { return _vm.deleteFunction(item); }}},[_vm._v("mdi-delete")])]}}])}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"color":"success","length":_vm.last_page,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }