<template>
  <v-row justify="center">
    <v-dialog
      persistent
      v-model="dialog"
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title>Novo Tenant</v-card-title>
        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            class="mt-6"
          >
          <v-row>
            <v-col
              cols="6">
              <v-text-field
                label="*Nome do tenant"
                v-model="name"
                :rules="[() => !!name || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="6">
               <v-text-field
                label="*Domínio do tenant"
                v-model="domain"
                :rules="[() => !!domain || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
              <v-text-field
                label="*Image do tenant"
                v-model="image"
                :rules="[() => !!image || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
          <v-row>
            <v-col
              cols="6">
              <v-text-field
                label="*db_database"
                v-model="database"
                :rules="[() => !!database || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="6">
              <v-text-field
                label="*db_hostname"
                v-model="hostname"
                :rules="[() => !!hostname || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6">
              <v-text-field
                label="*db_username"
                v-model="username"
                :rules="[() => !!username || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="6">
              <v-text-field
                label="*db_password"
                v-model="password"
                :rules="[() => !!password || 'Campo Obrigatorio']"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
            
            
            
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="error"
                @click="closeDialog"
                
              >
                <v-icon class="mr-1">mdi-cancel</v-icon> Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="submit"
                  
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
    props: {
      dialog: Boolean,
    },
    data() {
        return {
            name: '',
            domain: '',
            image: '',
            database: '',
            hostname: '',
            username: '',
            password: '',
            valid: true,
        }
    },

    methods: {
        
        closeDialog() {
            this.dialog = false
             this.$emit('changeDialog', this.dialog)
             this.$refs.form.reset()
            
        },

        
        
        submit() {
            const tenant = {
                name: this.name,
                domain: this.domain,
                image: this.image,
                database: this.database,
                hostname: this.hostname,
                username: this.username,
                password: this.password,
            }
            this.$store.dispatch('ADD_TENANT', tenant)
            this.$refs.form.reset()
            this.dialog = false
            this.$emit('changeDialog', this.dialog)
        }
    },
}
</script>

<style>

</style>