<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6">
        <v-text-field outlined label="CSC" v-model="component.enterprise.csc">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-file-input
          v-model="certificateFile"
          color="deep-purple accent-4"
          label="Importar Certificado"
          placeholder="Selecione o certificado"
          prepend-icon="mdi-paperclip"
          outlined
          @change="Preview_certified($event)"
        >
        </v-file-input>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Senha do certificado"
          type="password"
          v-model="component.enterprise.certified_password"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Chave do Token IBPT"
          v-model="component.enterprise.token_ibpt"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          outlined
          label="Token da nota"
          v-model="component.enterprise.token"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: false,
    files: [],
    url: undefined,
    certificateFile:null,
  }),
  methods: {
    Preview_certified(e) {
      e ? (this.url = URL.createObjectURL(e)) : (this.url = null);
      this.component.certificateFile = e;
    },
  },
};
</script>
