import axios from "axios";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

require("@/store/subscribers");
//producao
// axios.defaults.baseURL = "https://api.fiscal.topwaysystems.com.br/api"

//desenvolvimento
axios.defaults.baseURL = "https://api.fiscaldev.topwaysystems.com.br/api"

// axios.defaults.baseURL = "http://microfiscal:8029/api"

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
});