<template>
  <v-row justify="center">
    <v-dialog v-model="addDialog" max-width="600" persistent>
      <v-card>
        <v-container>
          <v-card-title>{{ title }} Usuário</v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" v-model="valid_form">
            <v-row class="ma-2">
              <v-checkbox
                v-model="component.user.status"
                label="Ativo"
              ></v-checkbox>
            </v-row>
            <v-row class="ma-2">
              <v-text-field
                v-model="component.user.name"
                class="mr-1"
                label="Nome"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
              <v-text-field
                v-model="component.user.email"
                label="E-mail"
                :rules="[rules.required]"
                outlined
              ></v-text-field>
            </v-row>
            <div v-if="component.changePassword">
              <v-row class="ma-2">
                <v-text-field
                  class="mr-1"
                  v-model="component.user.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'"
                  hint="Minimo 8 digitos"
                  label="Senha"
                  outlined
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-text-field
                  label="Confirmar Senha"
                  v-model="component.user_confirm_pass"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  :rules="[rules.required, rules.min]"
                  hint="Minimo 8 digitos"
                  @click:append="show2 = !show2"
                  outlined
                ></v-text-field>
              </v-row>
            </div>
            <v-row class="ma-2">
              <v-autocomplete
                :items="enterpriseItems"
                v-model="component.user.enterprises"
                item-text="fantasyName"
                item-value="id"
                outlined
                :rules="[rules.required]"
                label="Empresas"
                multiple
                chips
              ></v-autocomplete>
            </v-row>
            <v-row class="ma-2">
              <v-select
                :items="rolesItems[0]"
                item-text="label"
                v-model="component.user.roles"
                item-value="id"
                :rules="[rules.required]"
                outlined
                return-object
                label="Permissões"
                multiple
                chips
              ></v-select>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="actionDialog()" class="error">
              <v-icon class="mr-2">mdi-cancel</v-icon>Cancelar</v-btn
            >
            <v-btn @click="submit" :disabled="!valid_form" class="success">
              <v-icon class="mr-2">mdi-content-save</v-icon> Confirmar</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <cashierSetting v-if="dialogCashier" :component="this" />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
import { UPDATE_USER, CREATE_USER } from "../../../store/actions/user";

export default {
  components: {},
  props: {
    component: Object,
    addDialog: Boolean,
    actionDialog: Function,
    title: String,
  },
  data: () => ({
    dialogCashier: Boolean(),
    rolesItems: Array(),
    enterpriseItems: Array(),
    isValid: Boolean(),
    show1: Boolean(),
    show2: Boolean(),

    rules: {
      required: (value) => !!value || "Obrigatório",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
    },
  }),

  methods: {
    submit() {
      if (this.component.id === undefined) {
        this.createUser();
      } else {
        this.updateUser();
      }
    },
    destroyed() {
      delete this.component.user.id;
    },
    async createUser() {
      let user = { ...this.component.user };
      await this.$store.dispatch(CREATE_USER, user);
      this.component.close();
    },
    async updateUser() {
      let user = { ...this.user };
      await this.$store.dispatch(UPDATE_USER, user);
      this.component.close();
    },
  },
  async created() {
    let value = {
      page: 1,
      limit: 10,
      paginate: true,
    };
    await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
  },
  watch: {
    getEnterprises: function (val) {
      this.enterpriseItems = Object.values(val.data);
    },
  },
  computed: {
    ...mapGetters(["getEnterprises"]),
  },
};
</script>

<style></style>
