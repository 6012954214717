<template>
  <v-tabs vertical color="primary">
    <v-tab v-for="item in parameterList" :key="item.name">
      <!-- <v-icon left>
        {{ item.icon }}
      </v-icon> -->
      {{ item.name }}
    </v-tab>

    <v-tab-item v-for="item in parameterList" :key="item.name">
      <v-container>
        <Table :configs="item.item_paramters" />
      </v-container>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import Table from "../Table";
import { GET_ALL_PARAMETER } from "../../../store/actions/parameter";
import { mapGetters } from "vuex";
export default {
  components: { Table },
  data: () => ({
    parameterList: Array(),
  }),
  methods: {
    async getAllPameters() {
      let config = {
        page: 1,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_PARAMETER, config);
    },
  },
  computed: {
    ...mapGetters(["getParameter"]),
  },
  watch: {
    getParameter: function (val) {
      this.parameterList = val.data;
    },
  },
  created() {
    this.getAllPameters();
  },
};
</script>

<style></style>
