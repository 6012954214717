<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="tenants"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
           
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"

              v-on="on"
              @click="
                () => {
                  open_Dialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
       <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    search: String,
  },

  data() {
    return {
        headers: [
          { text: "Nome", value: "name" },
          { text: "Domínio", value: "domain" },
          { text: "Image", value: "image" },
          { text: "Database", value: "database" },
          { text: "Hostname", value: "hostname" },
          { text: "Username", value: "username" },
          { text: "Password", value: "password" },
        ],

        tenants: [],
    }
  },

  computed: {
  
    ...mapGetters(["getTenant"]),
  
  },

  watch: {
    getTenant: function (val) {
      this.tenants.push(val);
    },
  },
}
</script>

<style>

</style>