<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="plansItem"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
           
            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"

              v-on="on"
              @click="
                () => {
                  open_Dialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
       <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
  
  </div>
</template>

<script>
export default {
  props: {
    search: String,
    plansItem: Array,
  },

  data() {
    return {
        headers: [
          { text: "Item", value: "item" },
          { text: "Detalhes", value: "details" },
        ],
    }
  },

  computed: {
  
    
  
  },

  watch: {
    
  },
}
</script>

<style>

</style>