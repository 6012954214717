/* eslint-disable no-unused-vars */
import axios from "axios";

const {
  ADD_COMPANY_GROUP,
  UPDATE_COMPANY_GROUP,
  GET_ALL_COMPANY_GROUP,
  DESTROY_COMPANY_GROUP,
  GET_FILTER_COMPANY_GROUP,
} = require("./actions/companygroup");

const { SPLICE_ERRO } = require("./actions/alert_api");

const moduleCompanygroup = {
  state: () => ({
    companygroups: Object(),
  }),

  mutations: {
    GET_ALL_COMPANY_GROUP: (state, companygroups) => {
      state.companygroups = companygroups;
    },
    GET_FILTER_COMPANY_GROUP: (state, payload) => {
      state.companygroups = payload;
    },
    ADD_COMPANY_GROUP: (state, newCompanygroups) => {
      state.companygroups.data.push(newCompanygroups)
    },
    UPDATE_COMPANY_GROUP(state, editCompanygroups) {
      console.log('mutation');
      console.log(editCompanygroups);
      state.companygroups.data.Update(editCompanygroups);
    },

    DESTROY_COMPANY_GROUP: (state, id) => {
      let auxState = [...state.companygroups.data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.companygroups = [...auxState];
    },
  },

  actions: {
    GET_ALL_COMPANY_GROUP({ commit }, payload) {
      axios
        .post("companygroup/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_ALL_COMPANY_GROUP, response.data.companygroup);
        });
    },

    GET_FILTER_COMPANY_GROUP({ commit }, payload) {
      axios
        .get("companyGroup/autocomplete", {
          params: {
            search: payload.search,
            limit: payload.limit,
            page: payload.page,
            paginate: payload.paginate,
          },
        })
        .then((response) => {
          commit(GET_FILTER_COMPANY_GROUP, response.data[0]);
        });
    },

    ADD_COMPANY_GROUP({ commit }, payload) {
      console.log('action');
      console.log('payload', payload);
      return new Promise((resolve, reject) => {
        axios
          .post("companygroup/create", payload)
          .then((response) => {
            console.log('response', response);
            resolve();
            commit(ADD_COMPANY_GROUP, response.data.companygroup);
            commit(SPLICE_ERRO, {
              alert: "Grupo de empresa criado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    async UPDATE_COMPANY_GROUP({ commit }, payload) {
      console.log('action');
      console.log('payload', payload);
      return new Promise((resolve, reject) => {
        axios
          .post("companygroup/update", payload)
          .then((response) => {
            console.log('response', response);
            resolve();
            commit(UPDATE_COMPANY_GROUP, response.data.companygroup);
            commit(SPLICE_ERRO, {
              alert: "Grupo de empresa atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    DESTROY_COMPANY_GROUP({ commit }, payload) {
      console.log(payload);
      axios
        .post("companygroup/delete", payload)
        .then((response) => {
          console.log('teste');
          commit(DESTROY_COMPANY_GROUP, response.data.companygroup);
          commit(SPLICE_ERRO, {
            alert: "Grupo de empresa deletado com sucesso!",
            type: "S",
          });
        })
        .catch((r) => {
          let erros = r.response.data[0].errors;
          for (let erro in erros) {
            erros[erro].forEach((err) =>
              commit(SPLICE_ERRO, {
                alert: err,
                type: "E",
              })
            );
          }
        });
    },
  },

  getters: {
    getCompanygroup: (state) => ({
      ...state.companygroups,
    }),
  },
};

export default moduleCompanygroup;
