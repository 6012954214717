<template>
  <v-form ref="form" v-model="component.valid">
    <v-row>
      <v-col cols="2">
        <v-select
          label="Habilitar?"
          :items="status"
          item-text="text"
          item-value="value"
          v-model="component.enterprise.status"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="*Razão Social"
          v-model="component.enterprise.name"
          :rules="[
            () => !!component.enterprise.name || 'Campo Obrigatorio',
            () =>
              (!!component.enterprise.name &&
                component.enterprise.name.length >= 3) ||
              'Campo tem que ser maior ou igual a 3',
          ]"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="*Nome Fantasia"
          v-model="component.enterprise.fantasyName"
          :rules="[
            () => !!component.enterprise.fantasyName || 'Campo Obrigatorio',
            () =>
              (!!component.enterprise.fantasyName &&
                component.enterprise.fantasyName.length >= 3) ||
              'Campo tem que ser maior ou igual a 3',
          ]"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
          label="*CNPJ"
          v-model="component.enterprise.cnpj"
          v-mask="'##.###.###/####-##'"
          outlined
          :rules="cnpjRules"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          label="*--Selecione o Tipo da Empresa--"
          v-model="component.enterprise.enterprise_type"
          item-text="text"
          item-value="value"
          :items="enterprise_type"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          label="*--Selecione o Tipo da Tributário--"
          v-model="component.enterprise.tax_regime"
          :items="tax_regimes"
          item-text="text"
          item-value="value"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="*Inscrição Estadual"
          v-model="component.enterprise.registrationState"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*Inscrição Municipal"
          v-model="component.enterprise.registrationMunicipal"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="companyGroup"
          item-text="name"
          item-value="id"
          v-model="component.enterprise.company_group_id"
          :rules="campoRules"
          outlined
          label="*Grupo de Empresas"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
 
  </v-form>
</template>

<script>
 import { campoRules, cnpjRules } from "../script"
import { mapGetters } from "vuex";

export default {
  props: {
    component: Object(),
  },

  computed: { ...mapGetters(["getCompanygroup"]) },
  data: () => ({
    campoRules,
    cnpjRules,
    companyGroup: Array(),
    enterprise_type: [
      { text: "Atacadista", value: "A" },
      { text: "Indústria", value: "I" },
      { text: "Serviço", value: "S" },
      { text: "Varejo", value: "V" },
    ],
    tax_regimes: [
      { text: "Lucro Presumido", value: 3 },
      { text: "Lucro Real", value: 2 },
      { text: "Simples Nacional", value: 1 },
      { text: "Micro Empreendedor", value: 4 },
    ],
    status: [
      { text: "Ativo", value: "Ativo" },
      { text: "Inativo", value: "Inativo" },
       { text: "Bloqueado", value: "Bloqueado" },
    ],
  }),
  methods: {
    reset() {
      this.$refs.form.reset();
    },


  },
  watch: {
    getCompanygroup: function (val) {
      this.companyGroup = Object.values(val.data);
    },
  },
};
</script>
