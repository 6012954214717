/* eslint-disable no-unused-vars */
import VueRouter from "vue-router";
import store from "@/store";



export default (router = new VueRouter()) => {
    router.beforeEach((to, _, next) => {
        if (to.name !== "Login") {
          let auth = JSON.parse(localStorage.getItem("authenticated"));
          if (!auth) {
            store.commit("auth/SET_USER");
            store.commit("auth/SET_ISVALID", false);
            return next({
              name: "Login",
            });
          } else {
            store.commit("auth/SET_USER", auth);
            store.commit("auth/SET_ISVALID", true);
      
          }
        } else if (to.name === "Login") {
          let auth = JSON.parse(localStorage.getItem("authenticated"));
          if (auth) {
            store.commit("auth/SET_USER", auth);
            router.push({
              name: "Home",
            });
            return next({
              name: "Home",
            });
          }
        }
        next();
      });
}