/* eslint-disable no-unused-vars */


const {
    ADD_PLAN,
    
  } = require("./actions/plans");



const modulePlans = {
  state: () => ({
    planData: {},
    }),

  mutations: {
    ADD_PLAN(state, payload) {
      state.planData = payload
    },

  },
  actions: {
    ADD_PLAN(context, payload) {
      context.commit('ADD_PLAN', payload)
    }
  },
  
  getters: {
    getPlan: (state) => ({
      ...state.planData,
    }),
  },
};


export default modulePlans;