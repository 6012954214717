<template>
  <div>
    <v-data-table
      :headers="header"
      :items="results"
      :search="search"
      hide-default-footer
      no-data-text="Sem empresas registradas"
      no-results-text="Nenhum resultado encontrado"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  change_addDialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <destroyDialog :component="this" :destroy_object="destroy_object" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
     component: Object,
  },
  data: () => ({
    header: [
      { text: "Nome", value: "name" },
      { text: "E-mail", value: "email" },
      { text: "Ações", value: "actions" },
    ],
    currentItem: Object(),
    results: Array(),
    total: Number(),
    current: Number(),
  }),

  created() {
    this.component.getAll();
  },

  computed: {
    ...mapGetters(["getUsers"]),
  },


  watch: {
    getUsers: function(val) {

      console.log(val.data)
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
  },
};
</script>

<style></style>
