<template>
  <v-row justify="center">
    <v-dialog
      v-model="this.component.addDialog"
      fullscreen
      transition="dialog-bottom-transition"
      hide-overlay
    >
      <v-card>
        <v-toolbar color="success" class="white--text" flat>
          <v-btn
            icon
            dark
            @click="
              () => {
                close_addDialog();
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nova Empresa</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-divider></v-divider>
          <v-tabs>
            <v-tab href="#tab1">
              <v-icon class="mr-1">mdi-account-details</v-icon>
              Gerais
            </v-tab>

            <v-tab href="#tab2">
              <v-icon class="mr-1">mdi-home-account</v-icon> Endereço
            </v-tab>

            <v-tab href="#tab3">
              <v-icon class="mr-1">mdi-receipt</v-icon> Fiscal
            </v-tab>

            <v-tab href="#tab4">
              <v-icon class="mr-1">mdi-receipt</v-icon> Chaves
            </v-tab>

            <v-tab-item :eager="true" value="tab1" class="mt-6">
              <v-card flat>
                <TabGeral :component="this" ref="geral" />
              </v-card>
            </v-tab-item>

            <v-tab-item :eager="true" value="tab2" class="mt-6">
              <v-card flat>
                <TabAddress :component="this" ref="address" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab3" class="mt-6">
              <v-card flat>
                <TabFiscal :component="this" />
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab4" class="mt-6">
              <v-card flat>
                <TabKeyAccess :component="this" />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="() => clear()">
              <v-icon class="mr-2">mdi-cancel</v-icon> Limpar formulário</v-btn
            >
            <v-btn
              color="success"
              :disabled="!valid"
              class="mr-4"
              @click="
                () => {
                  submit();
                }
              "
              ><v-icon class="mr-2">mdi-content-save</v-icon>Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import TabGeral from "../Tab/TabGeral";
import TabAddress from "../Tab/TabAddress/";
import TabFiscal from "../Tab/TabFiscal/";
import TabKeyAccess from "../Tab/TabKeyAccess";

import {
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
} from "../../../store/actions/enterprise";
import { GET_ALL_COMPANY_GROUP } from "../../../store/actions/companygroup";
// import { dispatchGetAll } from "../../Methods/dispatchGetAll";

export default {
  components: { TabGeral, TabAddress, TabFiscal, TabKeyAccess },

  props: {
    component: Object(),
  },

  data: () => ({
    oldState: Object,
    companyGroup: [],
    cities: [],
    valid: Boolean(),
    certificateFile: null,
    enterprise: {
      status: String(),
      name: String(),
      fantasyName: String(),
      cnpj: String(),
      registrationState: String(),
      registrationMunicipal: String(),
      accountancy: String(),
      aliquot_pis: Number(),
      aliquot_cofins: Number(),
      conter: String(),
      crc: String(),
      token: String(),
      csc: String(),
      noteissung_enviroment: Number(),
      enterprise_type: String(),
      country_code: Number(),
      cnai: String(),
      crt: Number(),
      note_issuing_environment: String(),
      token_ibpt: String(),
      certified_path: String(),
      cnpj_authorization: String(),
      cpf_authorization: String(),
      company_group_id: Number(),
      tax_regime: Number(),
      certified_password: String(),
      street: String(),
      number: String(),
      complement: String(),
      district: String(),
      description_country: String(),
      telephone: String(),
      state: String(),
      city: {
        code: Number(),
        name: String(),
      },

      cep: String(),
    },
    newcity: "",
    initials: [
      { text: "Acre", value: "ac" },
      { text: "Alagoas", value: "al" },
      { text: "Amapá", value: "ap" },
      { text: "Amazonas", value: "am" },
      { text: "Bahia", value: "ba" },
      { text: "Ceará", value: "ce" },
      { text: "Espírito Santo", value: "ES" },
      { text: "Goiás", value: "go" },
      { text: "Maranhão", value: "ma" },
      { text: "Mato Grosso", value: "mt" },
      { text: "Mato Grosso do Sul", value: "ms" },
      { text: "Minas Gerais", value: "mg" },
      { text: "Pará", value: "pa" },
      { text: "Paraíba", value: "pb" },
      { text: "Paraná", value: "pr" },
      { text: "Pernambuco", value: "pe" },
      { text: "Piauí", value: "pi" },
      { text: "Rio de Janeiro", value: "rj" },
      { text: "Rio Grande do Norte", value: "rn" },
      { text: "Rio Grande do Sul", value: "rs" },
      { text: "Rondônia", value: "ro" },
      { text: "Roraima", value: "rr" },
      { text: "Santa Catarina", value: "sc" },
      { text: "São Paulo", value: "sp" },
      { text: "Sergipe", value: "se" },
      { text: "Tocatins", value: "to" },
      { text: "Distrito Federal", value: "df" },
    ],
  }),

  methods: {
    clear() {
      this.$refs.address.reset();
      this.$refs.geral.reset();
    },
    close_addDialog() {
      this.component.addDialog = !this.component.addDialog;
      this.clear();
    },

    submit() {
      this.enterprise.state = this.oldState.text;
      this.enterprise.city.code = this.newcity.id;
      this.enterprise.city.name = this.newcity.name;
      this.created_enterprises();
    },

    async created_enterprises() {
      const enterprise = { ...this.enterprise };
      enterprise.certificateFile = this.certificateFile,
      await this.$store
        .dispatch(ADD_ENTERPRISE, 
          enterprise
         
        )
        .then(() => {
          // this.close_addDialog();
        });
    },

    async update_enterprises() {
      const enterprise = { ...this.enterprise };
      await this.$store.dispatch(UPDATE_ENTERPRISE, enterprise).then(() => {
        this.close_addDialog();
      });
    },

    async mounterUpdate() {
      if (this.component.objectToUpdate.id != null) {
        this.enterprise = { ...this.component.objectToUpdate };
      }
    },

    getComponent() {
      return this._props.component;
    },

    async GetAllNoPage() {
      let value = {
        page: 1,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_COMPANY_GROUP, value);
    },
  },

  created() {
    this.GetAllNoPage();
    this.mounterUpdate();
  },

  watch: {
    oldState(val) {
      if (val) {
        axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val.value}/municipios`
          )
          .then((response) => {
            this.cities = response.data;
          });
      }
    },
  },

  beforeDestroy() {
    this.getComponent().objectToUpdate = {};
  },
};
</script>
