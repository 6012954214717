<template>
  <Container>
    <v-card elevation="5">
      <v-card-text>
        <v-row>
          <v-card-title class="ml-2">Nota Fiscal - Nfe</v-card-title>
          <v-spacer></v-spacer>
          <AddDialog
            :component="this"
            v-if="dialog"
            :title="title"
            :dialog="dialog"
            :actionDialog="close"
          />
          <!-- <v-btn color="success" class="mr-5 mt-4" @click="activeAdd()"
            >Adicionar</v-btn
          > -->
        </v-row>
        <v-text-field
          outlined
          class="ma-2"
          label="Pesquisar"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-card-text>
      <Table
        :component="this"
        :editItem="editItem"
        :changeDestroyDialog="changeDestroyDialog"
        :change_password="change_password"
      />
    </v-card>
    <AlertApi />
  </Container>
</template>

<script>
import Container from "../components/Nfe/Container";
import AddDialog from "../components/Nfe/AddDialog";
import Table from "../components/Nfe/Table";

import AlertApi from "../components/alert_api/alert_api";
import { GET_ALL_NFE } from "../store/actions/nfe";
import { mapGetters } from "vuex";
export default {
  components: {
    AddDialog,
    Container,
    Table,
    // DestroyDialog,
    // ChangePass,
    AlertApi,
  },
  data: () => ({
    dialog: Boolean(),
    currentItem: Object(),
    permition: undefined,

    title: String(),
    user_confirm_pass: String(),
    user_finish: Object(),
    allUsers: Array(),
    isLoading: Boolean(),
    isAuth: Boolean(),
    destroyDialog: Boolean(),
    changePassword: Boolean(),
    itemToDestroy: Object(),
    dialogPass: Boolean(),
  }),
  methods: {
    async getAll(page = 1) {
      let value = {
        page: page,
        limit: 10,
        paginate: true,
      };
      await this.$store.dispatch(GET_ALL_NFE, value);
      this.allUsers = this.getUsers;
    },
  },
  async mounted() {
    await this.getAll();
  },
  computed: {
    ...mapGetters(["getNFe"]),
  },
};
</script>

<style></style>
