<template>
  <Container>
    <v-card elevation="4" outlined>
      <v-card-text>
        <v-row>
          <v-card-title>Relatório de Notas Emitidas</v-card-title>
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select
              outlined
              dense
              return-object
              flat
              height="10px"
              label="Empresa"
              item-value="id"
              item-text="fantasyName"
              loader-height
              v-model="selectValue"
              :items="enterprise"
              >Adicionar</v-select
            >
          </v-col>
          <v-btn
            class="mt-3 mr-4"
            width="200"
            color="success"
            type="submit"
            @click="Generate()"
            :disabled="!valid"
          >
            <v-icon class="mr-2">mdi-file-chart</v-icon>Visualizar</v-btn
          >
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-container>
        <Form :component="this" ref="form" />
      </v-container>
    </v-card>
  </Container>
</template>

<script>
import Container from "../components/ReportNfe/Container"
import Form from "../components/ReportNfe/Form";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { GET_ALL_ENTERPRISE } from "../../../store/actions/enterprise";
// import { mapGetters } from "vuex";
// import axios from "axios";

export default {
  components: { Form,Container },

  data: () => ({
    dialog: Boolean(),
    valid: false,
    selectValue: Object,
    enterprise: [],
    report: {
      initialentrydate: null,
      finalentrydate: null,
      initialdateissue: null,
      finaldateissue: null,
      providerinitialcode: null,
      providerfinalcode: null,
      number_nf: null,
      providers: null,
    },
    provider: null,
  }),

//   methods: {
//     async Generate() {
//       let all_report = Object.assign({}, this.report);
//       const response = await axios.get("catalog/invoice", {
//         params: {
//           ...all_report,
//         },
//       });
//       this.dialog = !this.dialog;
//       this.$refs.form.reset();
//       this.$refs.form.validate();
//       this.$refs.form.resetValidation();
//       let arrayenterprise = [];
//       let arrayaddress = [];
//       let objeto = Object.assign({}, this.selectValue);
//       let objetoadress = Object.assign({}, this.selectValue.address);
//       arrayenterprise.push(objeto);
//       arrayaddress.push(objetoadress);
//       this.createPDF(response.data[0], arrayenterprise, arrayaddress);
//     },

//     createPDF(items, enterprise, address) {
//       items.forEach((e) => {
//         e.date_of_issue = e.date_of_issue.split("-").reverse().join("/");
//         e.entry_date = e.entry_date.split("-").reverse().join("/");
//         e.value_total_products = `R$ ${e.value_total_products
//           .toFixed(2)
//           .replace(".", ",")}`;
//         e.value_total_note = `R$ ${e.value_total_note
//           .toFixed(2)
//           .replace(".", ",")}`;
//         e.base_calc_icms_total = `R$ ${e.base_calc_icms_total
//           .toFixed(2)
//           .replace(".", ",")}`;
//         e.value_icms_total = `R$ ${e.value_icms_total
//           .toFixed(2)
//           .replace(".", ",")}`;
//       });
//       let data = new Date();
//       var doc = new jsPDF("p", "pt");
//       var columns = [
//         { title: "Data da Emissão", dataKey: "date_of_issue" },
//         { title: "Data de Entrada", dataKey: "entry_date" },
//         { title: "Número da Nota", dataKey: "number_grade" },
//         { title: "Série da Nota", dataKey: "series" },
//         { title: "Valor dos Produtos", dataKey: "value_total_products" },
//         { title: "Valor da Nota Fiscal", dataKey: "value_total_note" },
//         { title: "Base cálculo e icms", dataKey: "base_calc_icms_total" },
//         { title: "Valor do ICMS", dataKey: "value_icms_total" },
//       ];
//       doc.setLineWidth(1);
//       //doc.rect(20, 20, 555, 800);
//       doc.line(40, 85, 555, 85);
//       doc.line(40, 125, 555, 125);

//       doc.setFontSize(7);
//       doc.text(`Usuário : Luiz`, 430, 40);
//       doc.text(`${data.toLocaleString()}`, 430, 50);
//       doc.setFontSize(15);
//       doc.text("RELATÓRIO DE ENTRADA DE NOTAS", 160, 70);
//       doc.setFontSize(7);
//       doc.text(`CNPJ : ${enterprise[0].cnpj}`, 40, 100);
//       doc.text(`Razão Social : ${enterprise[0].name}`, 140, 100);
//       doc.text(`Nome Fantasia : ${enterprise[0].fantasyName}`, 430, 100);
//       doc.text(`Rua : ${address[0].street}`, 40, 115);
//       doc.text(`Numero : ${address[0].number}`, 200, 115);
//       doc.text(`Cidade : ${address[0].city}`, 255, 115);
//       doc.text(`Estado : ${address[0].state}`, 400, 115);
//       doc.text(`CEP : ${address[0].cep}`, 500, 115);

//       doc.autoTable(columns, items, {
//         columnStyles: {
//           //
//         },
//         headStyles: {
//           fillColor: [230, 230, 230],
//           textColor: [54, 54, 54],
//         },
//         startY: 145,
//         theme: "grid",
//       });
//       const pages = doc.internal.getNumberOfPages();
//       // const pageWidth = doc.internal.pageSize.width;  //Optional
//       // const pageHeight = doc.internal.pageSize.height;  //Optional
//       //console.log(pageWidth , pageHeight);
//       doc.setFontSize(8); //Optional
//       for (let j = 1; j < pages + 1; j++) {
//         // let horizontalPos = pageWidth / 2;  //Can be fixed number
//         // let verticalPos = pageHeight - 10;  //Can be fixed number
//         doc.setLineWidth(1);
//         doc.rect(20, 20, 555, 800);
//         // doc.line(40, 85, 555, 85);
//         // doc.line(40, 125, 555, 125);
//         doc.setPage(j);
//         doc.text(`${j} / ${pages}`, 550, 40);
//       }
//       doc.output("dataurlnewwindow");
//       //doc.save(pdfName + ".pdf");
//     },

//     async GetAllNoPage() {
//       let value = {
//         page: null,
//         limit: null,
//         paginate: false,
//       };
//       await this.$store.dispatch(GET_ALL_ENTERPRISE, value);
//       // await dispatchGetAll(this, GET_ALL_PROVIDER, {
//       //   type_people: "PhysicalPeople",
//       //   page,
//       // });
//       // await dispatchGetAll(this, GET_ALL_PROVIDER, {
//       //   type_people: "LegalPeople",
//       //   page,
//       // });
//     },
//   },

//   computed: {
//     ...mapGetters([
//       "getPhisycalProvider",
//       "getLegalProvider",
//       "getEnterprises",
//     ]),
//   },

//   created() {
//     this.GetAllNoPage();
//     // this.providers = this.getPhisycalProvider.concat(this.getLegalProvider)
//     // this.providers.sort(function (a, b) {
//     //   return a.id - b.id

//     // })
//     //   console.log(100, this.providers);
//   },

//   watch: {
//     getEnterprises(val) {
//       this.enterprise = Object.keys(val).map((key) => val[key]);
//       let aux = Object.assign([], this.enterprise[0]);
//       this.selectValue = aux;
//     },

//     // getLegalProvider(val){
//     //   this.provider = this.getPhisycalProvider.concat(val)
//     //   this.provider.sort(function (a, b) {
//     //     return a.id - b.id
//     //   })
//     // }
//   },
};
</script>

<style>
</style>