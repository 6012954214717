<template>
  <div>
    <v-navigation-drawer
      v-model="component.drawer"
      width="300"
      temporary
      app
      class="SideNavbar"
      color="success"
    >
      <NavList :componet="this" v-if="component.drawer" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import NavList from "./Navlist/index.vue";

import "./styles.css";

export default {
  props: {
    component: Object(),
  },
  data: () => ({}),
  components: {
    NavList,
  },
};
</script>

