<template>
  <v-row justify="center">
    <v-dialog persistent v-model="this.addDialogAliquot" max-width="600">
      <v-card>
        <v-card-title primary-title>{{ action }} Nota fiscal</v-card-title>

        <v-container>
          <v-divider></v-divider>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
            class="mt-6"
          >
            <v-col>
              <v-text-field
                label="*Descrição"
                color="success"
                v-model="description"
                :rules="rules"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="*Aliquota - Valor entre 0 e 100 %."
                color="success"
                type="number"
                v-model="aliquot"
                suffix="%"
                maxlength="5"
                :rules="[
                  () => !!aliquot || 'Campo Obrigatorio',
                  () =>
                    (!!aliquot && aliquot.length >= 1 && aliquot <= 100) ||
                    'Campo tem que ser maior do que 1 e menor que 100',
                ]"
                outlined
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error" @click="closeAliquotDialog()">
                <v-icon class="mr-1">mdi-cancel</v-icon>
                Cancelar</v-btn
              >
              <v-btn
                color="success"
                class="mr-4"
                :disabled="!valid"
                @click="
                  () => {
                    submit();
                  }
                "
              >
                <v-icon class="mr-1">mdi-content-save</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { ADD_ALIQUOT, UPDATE_ALIQUOT } from "../../../store/actions/aliquot";

export default {
//   props: {
//     addDialogAliquot: Boolean,
//     component: Object,
//     action: String,
//   },

  data() {
    return {
    //   valid: true,
    //   id: String(),
    //   description: String(),
    //   aliquot: String(),
    //   resultAliquot: null,
    //   dialog_alert: Boolean(),
    //   title_error: String(),
    //   listErrors: Array(),
    //   rules: [
    //     (description) => !!description || "Campo Obrigatório.",
    //     (description) =>
    //       (description && description.length >= 2) || "Mínimo 2 caracteres",
    //   ],
    };
  },
  methods: {
    // closeAliquotDialog() {
    //   this.component.addDialogAliquot = false;
    // },

    // getComponent() {
    //   return this.component;
    // },

    // async create_aliquot() {
    //   const aliquot = {
    //     description: this.description,
    //     aliquot: this.aliquot,
    //   };

    //   await this.$store.dispatch(ADD_ALIQUOT, aliquot).then(() => {
    //     this.closeAliquotDialog();
    //   });
    // },

    // submit() {
    //   this.id == undefined ? this.create_aliquot() : this.update_aliquot();
    // },

    // async update_aliquot() {
    //   const aliquot = {
    //     id: this.id,
    //     description: this.description,
    //     aliquot: this.aliquot,
    //   };

    //   await this.$store.dispatch(UPDATE_ALIQUOT, aliquot).then(() => {
    //     this.closeAliquotDialog();
    //   });
    // },

    // show_aliquot(aliquot) {
    //   this.aliquot = aliquot.aliquot;
    //   this.description = aliquot.description;
    // },

    // mountedObject() {
    //   this.object = { ...this.getComponent().objectToUpdate };
    //   this.id = this.object.id;
    //   this.description = this.object.description;
    //   if (this.id >= 0) {
    //     this.aliquot = this.object.aliquot.toString();
    //   } else {
    //     this.aliquot = this.object.aliquot;
    //   }
    // },
  },

//   async created() {
//     // this.mountedObject();
//   },
  
//   beforeDestroy() {
//     // this.getComponent().objectToUpdate = {};
//   },
};
</script>
