<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="3"
        v-for="(image, index) in listImages"
        :key="image.title"
      >
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-card
            :to="image.path"
            class="card rounded-lg"
            :color="listColors[index]"
            :elevation="hover ? 16 : 2"
          >
            <div>
              <v-col class="d-flex justify-center">
                <v-img
                  class="mt-3"
                  max-height="230"
                  max-width="100"
                  :src="image.image"
                />
              </v-col>
              <v-col>
                <p>{{ image.title }}</p>
              </v-col>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Users from "../assets/users.png";
import Plans from "../assets/plans.png";
import Group from "../assets/companyGroup.png";
import Enterprise from "../assets/enterprise.png";
import License from "../assets/license.png";
import Tenant from "../assets/tenant.png";


export default {
  data: function () {
    return {
      paleteColors: [
        "#ffadad",
        "#ffd6a5",
        "#fdffb6",
        "#caffbf",
        "#9bf6ff",
        "#a0c4ff",
        "#bdb2ff",
        "#ffc6ff",
        //entrada de uma paleta de cor
        "#f94144",
        "#f3722c",
        "#f8961e",
        "#f9844a",
        "#f9c74f",
        "#90be6d",
        "#43aa8b",
        "#4d908e",
        "#577590",
        "#277da1",
      ],
      listColors: Array(),
      listImages: [
        { image: Group, path: "/CompanyGroup" , title: "Grupo de empresa" },
        { image: Enterprise, path: "/Enterprise" , title: "Empresa" },
        { image: License, path: "/License", title: "Licença" },
        { image: Plans, path: "/Plans", title: "Planos" },
        { image: Tenant, path: "/Tenant", title: "Tenants" },
        { image: Users, path: "/User", title: "Usuários" },
      ],
    };
  },
  methods: {
    setColors() {
      for (let index = 0; index < this.listImages.length; index++) {
        this.listColors.push(
          this.paleteColors[
            Math.floor(Math.random() * this.paleteColors.length)
          ]
        );
      }
    },
    onKey(e) {
      let keycode = e.key;
      switch (keycode) {
        case "F1":
          this.$router.push("Client").catch(() => {});
          break;
        case "F2":
          this.$router.push("produtos").catch(() => {});
          break;
        case "F3":
          this.$router.push("Seller").catch(() => {});
          break;
        case "F4":
          this.$router.push("Sales").catch(() => {});
          break;
        case "F8":
          this.$router.push("Notes").catch(() => {});
          break;
        case "F9":
          this.$router.push("users").catch(() => {});
          break;
      }
    },
  },
  created() {
    this.setColors();
  },
  mounted() {
    document.addEventListener("keyup", this.onKey);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onKey);
  },
};
</script>

<style>
.card {
  width: 400px;
  height: 220px;
  margin-top: 2px;
  /* align-self: center */
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleMain {
  font-family: "Courier New", Courier, monospace;
  padding-top: 20px;
  color: white;
  font-size: 1.8rem;
}
.subtitleMain {
  margin-top: 20px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 1.7rem;
  color: white;
}
p {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: black;
}
</style>