import axios from "axios";
const {
    UPDATE_USER,
    GET_ALL_USER,
    CREATE_USER,
    DELETE_USER
} = require("./actions/user");
const {
    SPLICE_ERRO
} = require('./actions/alert_api')

const moduleUsers = {
    state: () => ({
        users: Object(),
    }),
    mutations: {
        GET_ALL_USER: (state, users) => {
            state.users = users
        },
        CREATE_USER: (state, payload) => {
            state.users.data.push(payload)
        },
        UPDATE_USER: (state, payload) => {
            state.users.data.Update(payload)
        },
        DELETE_USER: (state, id) => {
            let auxState = [...state.pdv.data];
            let index = auxState.indexOf(auxState.find(value => value.id === id))
           
            if (index >= 0) {
                auxState.splice(index, 1)
            }
            state.pdv.data = [...auxState]
        },
      

    },

    actions: {
        async GET_ALL_USER({
            commit
        }, payload) {
            await axios.post("user/index", {
                ...payload
            }).then((response) => {
                commit(GET_ALL_USER,response.data.users);
                SPLICE_ERRO
            }).catch(r => {
                console.log(r.response, 'rrr');
            });
        },
        async CREATE_USER({
            commit
        }, payload) {
            await axios.post("user/create", {
                ...payload
            }).then((response) => {  
                commit(CREATE_USER, response.data.users);
                commit(SPLICE_ERRO, {
                    alert: response.data.mensagem[0],
                    type: 'S'
                })
            }).catch(r => {
                commit
                throw new Error(r)
            });
        },

        async UPDATE_USERS({
            commit
        }, payload) {

            await axios.post("user/update", {
                ...payload
            }).then((response) => {

                commit(UPDATE_USER, JSON.parse(JSON.stringify(response.data[0])));
                commit(SPLICE_ERRO, {
                    alert: response.data.messagens[0],
                    type: 'S'
                })
            }).catch(r => {
                console.log(r, 'rrr');
            });
        },
        async DELETE_USER({
            commit
        }, payload) {
            await axios.post("user/delete", {
                ...payload
            }).then((response) => {
                commit(DELETE_USER, payload.id);
                commit(SPLICE_ERRO, {
                    alert: response.data.mensagem[0],
                    type: 'S'
                })
            }).catch(e => {
                console.log(e.response)
            })

        },

    },

    getters: {
        getUsers: (state) => ({...state.users}
            
        ),
    },
};

export default moduleUsers;