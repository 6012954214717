<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col>
        <v-text-field
          label="*Contabilidade"
          v-model="component.enterprise.accountancy"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*Contador"
          v-model="component.enterprise.conter"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="*CRC"
          v-model="component.enterprise.crc"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          label="*Ambiente de Emissão de Nota"
          v-model="component.enterprise.noteissung_enviroment"
          :items="tbAmb"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="*Cnai"
          v-model="component.enterprise.cnai"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="*Cnpj para autorização NF"
          v-model="component.enterprise.cnpj_authorization"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="*Cpf para autorização NF"
          v-model="component.enterprise.cpf_authorization"
          :rules="campoRules"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          label="*Codigo de Situação tributaria"
          :items="codeSituationTax"
          v-model="component.enterprise.crt"
          outlined
          :rules="campoRules"
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
// import { campoRules } from "../script";
export default {
  props: {
    component: Object(),
  },
  data: () => ({
    valid: false,
    tbAmb: [
      { text: "Produção", value: "1" },
      { text: "Homologação", value: "2" },
    ],
    codeSituationTax: [
      { text: "Simples Nacional", value: 1 },
      { text: "Simples Nacional - Crédito", value: 2 },
      { text: "Regime Normal ", value: 3 },
    ],
  }),
};
</script>
