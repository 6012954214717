<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="results"
      :search="search"
      no-data-text="Nenhum resultado encontrado"
      no-results-text="Nenhum resultado encontrado"
      hide-default-footer
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->

            <v-icon
              color="yellow darken-4"
              class="mr-2"
              v-bind="attrs"

              v-on="on"
              @click="
                () => {
                  open_Dialog(item);
                }
              "
              >mdi-pencil</v-icon
            >
          </template>
          <span>Editar esse Item</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <!-- exemplo de como deve aplicar as permissoes na view -->
            <v-icon
              color="red darken-4"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
              @click="
                () => {
                  deleteItem(item);
                }
              "
              >mdi-delete</v-icon
            >
          </template>
          <span>Deletar esse Item</span>
        </v-tooltip>
      </template>
    </v-data-table>
       <v-divider></v-divider>
    <v-pagination
      v-model="current"
      :length="total"
      color="success"
      :total-visible="10"
    ></v-pagination>
    <DestryDialog :component="this" :destroy_companyGroup="destroy_companyGroup" />
  </div>
</template>

<script>
import DestryDialog from "./../destroyDialog/index";

export default {
  props: {
    component: Object,
    getters: Object,
    destroy_companyGroup: Function,
    search: String,
    headers: Array,
    open_Dialog: Function,
  },

  components: { DestryDialog },

  data: () => ({
    destroyDialog: false,
    currentItem: {},
    results: Array(),
    total: Number(),
    current: Number(),
    itemSelectedOnClick: Object(),
  }),

  methods: {
    deleteItem(item) {
      this.destroyDialog = true;
      this.currentItem = item;
    },
    
    handleClick(i) {
      this.itemSelectedOnClick = i;
    },
  },

  created() {
    this.component.GetAll();
  },

  watch: {
    getters: function (val) {
      this.results = val.data;
      this.total = val.last_page;
      this.current = val.current_page;
    },
    current: function (val) {
      if(this.component.filter){
        this.component.companyGroup_filter && this.component.companyGroup_filter(val);
      }else{
        this.component.GetAll && this.component.GetAll(val);
      }
    },
  },
};
</script>
