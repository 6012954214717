<template>
    <v-card outlined color="blue-grey lighten-5">
      <v-row justify="center" class="ml-5">
        <v-col cols="5" md="4" sm="4" lg="3" xl="3">
          <v-card max-width="220">
            <v-row justify="center">
              <p class="titleInfos">Número de notas Emitidas</p>
            </v-row>
            <v-card-title class="justify-center text-h4 blue--text"
              >100</v-card-title
            >
            <v-card-actions>
              <v-row justify="center">
                <p>Notas Emitidas</p>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" sm="4" lg="3" xl="3">
          <v-card max-width="220">
            <v-row justify="center">
              <p class="titleInfos">Número de Cupons Emitidos</p>
            </v-row>
            <v-card-title class="justify-center text-h4 deep-orange--text"
              >300</v-card-title
            >
            <v-card-actions>
              <v-row justify="center">
                <p>Cupons Emitidos</p>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" sm="4" lg="3" xl="3">
          <v-card max-width="220">
            <v-row justify="center">
              <p class="titleInfos">Notas canceladas</p>
            </v-row>
            <v-card-title class="justify-center text-h4 red--text"
              >10</v-card-title
            >
            <v-card-actions>
              <v-row justify="center">
                <p>Notas canceladas</p>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>
      <v-row>
        <v-col class="ma-2">
          <v-row justify="center">
            <v-card class="mx-auto text-center" max-width="500">
              <GraphicSales />
            </v-card>
          </v-row>
        </v-col>
        <v-col class="ma-2">
          <v-card class="mx-auto text-center" max-width="500" height="326">
            <GraphicCashier />
          </v-card>
        </v-col>
      </v-row>
    </v-card>
 
</template>

<script>
import GraphicSales from "./Grafic_Sales";
import GraphicCashier from "./Grafic_Cashier";

export default {
  components: {
    GraphicSales,
    GraphicCashier,
  },
  data: () => ({}),
};
</script>

<style scoped>
.titleDashboard {
  font-family: "Quicksand", sans-serif;
  font-size: 3rem;
  color: #286a7d;
  margin: auto;
}
.titleInfos {
  font-family: "Quicksand", sans-serif;
  font-size: 1.2rem;
  margin: auto;
}
.textInfos {
  font-family: "Quicksand", sans-serif;
  font-size: 1.9rem;
  margin: auto;
}
</style>
