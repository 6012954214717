<template>
  
    <v-card elevation="5">
      <v-card-text>
      <v-row>
    
    <v-spacer></v-spacer>
    <v-col class="d-flex" cols="12" sm="3">
      <v-select
        outlined
        dense
        return-object
        flat
        height="10px"
        label="Licença"
        item-value="id"
        item-text="fantasyName"
        loader-height
        >Adicionar</v-select
      >
    </v-col>
  
    <v-btn
      color="success mr-2 mt-3"
      @click="openDialog"
      >Adicionar</v-btn
    >
  </v-row>
        <v-card-title primary-title>Licenças</v-card-title>
      </v-card-text>
      <v-card-text>
        <v-text-field
          class="mx-4"
          label="Pesquisar"
          outlined
          v-model="search"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-card-text>
     
      <add-dialog 
        :dialog="dialog"
        @changeDialog="dialog = $event" 
        />
        
      <Table 
        :search="search"
      />
      
    </v-card>
    
</template>

<script>
import AddDialog from "../components/License/AddDialog"
import Table from "../components/License/Table"
export default {
    components: {
      AddDialog,
      Table,
    },

    data() {
      return {
        filter: '',
        dialog: false,
        search: '',
      }
    },
    
    methods: {
      openDialog() {
        this.dialog = true
      }
    },
}
</script>

<style>

</style>