import axios from "axios";
const { SPLICE_ERRO } = require("../store/actions/alert_api");

export default {
  namespaced: true,
  state: {
    token: null,
    isAuth: false,
    user: null,
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_ISVALID(state, token) {
      state.isAuth = token;
    },

    SET_USER(state, data) {
      state.user = data || {
        user: {},
      };
      if (data) {
        let hasExpired = Object.prototype.hasOwnProperty.call(data, "status");
        if (hasExpired) {
          localStorage.removeItem("token");
          localStorage.removeItem("authenticated");
        } else {
          localStorage.setItem("authenticated", JSON.stringify(data));
        }
      }
    },
  },

  actions: {
    async login({ dispatch, commit }, credentials) {
      try {
        const response = await axios.post("login", credentials);

        return dispatch("attempt", response.data.token);
      } catch (error) {
        let erros = error.response.data.errors;
        //    for (let erro in erros) {
        commit(
          SPLICE_ERRO,
          {
            alert: erros,
            type: "E",
          },
          {
            root: true,
          }
        );
      }
    },
    async attempt({ commit, state }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }
      try {
        const response = await axios.get("user");
        commit("SET_USER", response.data);
      } catch (e) {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },

    async logOut({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
      localStorage.removeItem("token");
      localStorage.removeItem("authenticated");

      window.location.href = "/";
    },
  },
  getters: {
    authenticated(state) {
      return state.token && state.user;
    },
    user: (state) => state.user || {},
    userLogin: (state) => state.user || {},
    getisAuth: (state) => state.isAuth,
    getUsers: (state) => state.user,
  },
};
