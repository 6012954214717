<template>
  <v-container fluid>
    <v-data-table
      :headers="header"
      :items="result"
      no-data-text="Sem registro!"
      hide-default-footer
      :footer-props="{
        'items-per-page-options': [5, 10, 20, -1],
        'items-per-page-text': 'Items por página',
      }"
      :loading="component.isload"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          color="yellow darken-4"
          class="mr-2"
          @click="
            () => {
              edit_item(item);
            }
          "
          >mdi-pencil</v-icon
        >
        <v-icon
          color="red darken-4"
          class="mr-2"
          @click="() => deleteFunction(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        color="success"
        v-model="page"
        :length="last_page"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
  props: {
    edit_item: Function,
    component: Object,
  },
  data: () => ({
    header: [
      { text: "Código", value: "id" },
      { text: "Nome", value: "label" },
      { text: "Descrição", value: "description" },
      { text: "Nome Display", value: "display_name" },
      { text: "Ações", value: "actions" },
    ],
    page: Number(1),
    current_page: Number(0),
    last_page: Number(0),
  }),
//   computed: {
//     ...mapGetters(["getRoles"]),
//   },
//   methods: {
//     deleteFunction(item) {
//       this.component.currentItem = item;
//       this.component.destroyDialog = !this.component.destroyDialog;
//     },
//     reMakePaginate() {
//       let data = { ...this.getRoles[0] };
//       this.page = data.current_page;
//       this.current_page = data.current_page;
//       this.last_page = data.last_page;
//     },
//   },
//   watch: {
//     page: async function (val) {
//       if (val != this.current_page) {
//         await this.component.getAll(val);
//         this.reMakePaginate();
//       }
//     },
//     getRoles: async function () {
//       this.reMakePaginate();
//     },
//   },getRoles[0] ? getRoles[0].data : []
};
</script>

<style>
</style>