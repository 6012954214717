<template>
  <div>
    <apexchart
      type="bar"
      width="500"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    series: [
   
      {
        name: "Notas emitidas",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
        ],
      },
      yaxis: {
        title: {
          text: "Notas emitidas (Qtd)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "R$ " + val;
          },
        },
      },
    },
  }),
};
</script>
