const ADD_ENTERPRISE = "ADD_ENTERPRISE";
const UPDATE_ENTERPRISE = "UPDATE_ENTERPRISE";
const GET_ALL_ENTERPRISE = "GET_ALL_ENTERPRISE";
const DESTROY_ENTERPRISE = "DESTROY_ENTERPRISE";

module.exports = {
    ADD_ENTERPRISE,
    UPDATE_ENTERPRISE,
    GET_ALL_ENTERPRISE,
    DESTROY_ENTERPRISE,

}