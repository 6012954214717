/* eslint-disable no-unused-vars */
import axios from "axios";
import { SPLICE_ERRO } from "./actions/alert_api";

const {
  ADD_ENTERPRISE,
  UPDATE_ENTERPRISE,
  GET_ALL_ENTERPRISE,
  DESTROY_ENTERPRISE,
} = require("./actions/enterprise");

const moduleEnterprise = {
  state: () => ({
    enterprises: Object(),
  }),

  mutations: {
    GET_ALL_ENTERPRISE: (state, enterprises) => {

      state.enterprises = enterprises;
    },

    ADD_ENTERPRISE: (state, newEnterprises) => {
      state.enterprises.data.push(newEnterprises);
    },
    UPDATE_ENTERPRISE(state, payload) {
      let editEnterprise = payload[0];
      state.enterprises[0].data.Update(editEnterprise);
    },
    DESTROY_ENTERPRISE: (state, id) => {
      let auxState = [state.enterprises[0].data];
      let index = auxState.indexOf(auxState.find((value) => value.id === id));
      if (index >= 0) {
        auxState.splice(index, 1);
      }
      state.enterprises = [...auxState];
    },
  },
  actions: {
    GET_ALL_ENTERPRISE({ commit }, payload) {
      axios
        .post("enterprise/index", {
          page: payload.page,
          limit: payload.limit,
          paginate: payload.paginate,
        })
        .then((response) => {
          commit(GET_ALL_ENTERPRISE, response.data.enteprises);
        });
    },

    ADD_ENTERPRISE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        var form = new FormData()
        for (let key in payload) {
          form.append(key, payload[key])
        }
        axios
          .post("enterprise/create", form, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
            resolve();
            commit(ADD_ENTERPRISE, response.data.enteprises);
            commit(SPLICE_ERRO, {
              alert: "Cadastrado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    UPDATE_ENTERPRISE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise/update", payload)
          .then((response) => {
            resolve();
            commit(UPDATE_ENTERPRISE, response.data[0]);
            commit(SPLICE_ERRO, {
              alert: "Atualizado com sucesso!",
              type: "S",
            });
          })
          .catch(() => {
            reject();
          });
      });
    },

    DESTROY_ENTERPRISE({ commit }, payload) {
      axios
        .post("enterprise/delete", {
          id: payload.id,
          company_group_id: payload.company_group_id,
        })
        .then((response) => {
          commit(DESTROY_ENTERPRISE, payload.id);
          commit(SPLICE_ERRO, {
            alert: response.data[0].msg,
            type: "S",
          });
        });
    },
  },

  getters: {
    getEnterprises: (state) => ({
      ...state.enterprises,
    }),
  },
};

export default moduleEnterprise;
