/* eslint-disable no-unused-vars */


const {
    ADD_LICENSE,
    
  } = require("./actions/license");



const moduleLicense = {
  state: () => ({
    licenseData: {},
    }),

  mutations: {
    ADD_LICENSE(state, payload) {
      state.licenseData = payload
    },

  },
  actions: {
    ADD_LICENSE(context, payload) {
      context.commit('ADD_LICENSE', payload)
    }
  },
  
  getters: {
    getLicense: (state) => ({
      ...state.licenseData,
    }),
  },
};


export default moduleLicense;